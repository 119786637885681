export type CustomValidationResponse = {
  isValid: boolean;
  errorMessage: string;
};

export const percentageInputValidation = (
  val: string
): CustomValidationResponse => {
  if (parseInt(val) > 100) {
    return {
      isValid: false,
      errorMessage: 'Percentages must be 100 or below',
    };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

export const ppakWhPurchasedValidation = (
  val: string,
  annualkWhPurchased: any
): CustomValidationResponse => {
  if (annualkWhPurchased && parseInt(val) > annualkWhPurchased) {
    return {
      isValid: false,
      errorMessage: `Cannot exceed Fuel Scope Two kWh purchased (${annualkWhPurchased}).`,
    };
  }

  return {
    isValid: true,
    errorMessage: '',
  };
};

interface ICheckForErrorsProps {
  dbKey: string;
  isString?: boolean;
  customValidationFunction?: (val: string) => CustomValidationResponse;
}

export const checkForErrors = (props: ICheckForErrorsProps, val: string) => {
  const { dbKey, isString, customValidationFunction } = props;
  const errors: any[] = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (isNaN(val) && !isString) {
    errors.push({ [dbKey]: 'Value must be a Number.' });
  }

  if (!isString && Number(val) > 2147483647) {
    errors.push({ [dbKey]: 'Exceeded Max value.' });
  }

  if (customValidationFunction) {
    const validationResult = customValidationFunction(val);
    !validationResult.isValid &&
      errors.push({ [dbKey]: validationResult.errorMessage });
  }

  return errors;
};
